<template>
  <div>
    <RegisterSettings v-model="settings" type="course"></RegisterSettings>
    <NoteAddButton
      fab
      small
      bottom
      fixed
      right
      color="primary"
      v-if="canAddNote"
      :defaults="{
        course: course,
      }"
      @updated="$emit('updated')"
    />

    <RegisterLessons
      :course="course"
      :startDate="course ? course.startDate : null"
      :endDate="course ? course.endDate : null"
      :showAbsences="settings.showAbsences"
      :showEvents="settings.showEvents"
    />
  </div>
</template>

<script>
import NoteAddButton from "common/components/NoteAddButton.vue";
import RegisterLessons from "common/components/Register/RegisterLessons.vue";
import RegisterSettings from "common/components/Register/RegisterSettings.vue";
export default {
  name: "CourseRegister",
  components: { NoteAddButton, RegisterLessons, RegisterSettings },
  props: ["course"],

  data() {
    return {
      settings: {
        focus: "",
        showEvents: false,
        showAbsences: true,
        view: "list",
      },
    };
  },

  computed: {
    canAddNote() {
      return (
        this.course &&
        this.course.teachers &&
        this.course.teachers.some((el) => this.$_isPerson(el))
      );
    },
  },
};
</script>
